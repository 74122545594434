export const StorageService = {
  getAccessToken() {
    return _localstorage.getItem('accessToken');
  },
  setAccessToken(value) {
    return _localstorage.setItem('accessToken', value);
  },
  getAuthRefreshToken() {
    return _localstorage.getItem('refreshToken');
  },
  setAuthRefreshToken(value) {
    return _localstorage.setItem('refreshToken', value);
  },
  getWId() {
    return _localstorage.getItem('wid');
  },
  setWId(value) {
    return _localstorage.setItem('wid', value);
  },
  getXPN() {
    return _localstorage.getItem('x-pn');
  },
  setXPN(value) {
    return _localstorage.setItem('x-pn', value);
  },
  getUserId() {
    return _localstorage.getItem('userId');
  },
  setUserId(value) {
    return _localstorage.setItem('userId', value);
  },
  getMobile() {
    return _localstorage.getItem('mob');
  },
  setMobile(value) {
    return _localstorage.setItem('mob', value);
  },
  setReferralCode(value) {
    return _localstorage.setItem('referralCode', value);
  },
  getReferralCode() {
    return _localstorage.getItem('referralCode');
  },
  getCountryCode() {
    return _localstorage.getItem('countryCode');
  },
  setCountryCode(value) {
    return _localstorage.setItem('countryCode', value);
  },
  getLanguage() {
    return _localstorage.getItem('Language') || 'ENGLISH';
  },
  setLanguage(value) {
    return _localstorage.setItem('Language', value);
  },
  getTermsOfUsageAccepted() {
    return _localstorage.getItem('termsOfUsageAccepted');
  },
  setTermsOfUsageAccepted(value) {
    return _localstorage.setItem('termsOfUsageAccepted', value);
  },
  getPermissionsDenied() {
    return _localstorage.getItem('permissionsDenied');
  },
  setPermissionsDenied(value) {
    return _localstorage.setItem('permissionsDenied', value);
  },
  setIsLandscape(value) {
    return _localstorage.setItem('isLandscape', value, true);
  },
  getIsLandscape(value) {
    return _localstorage.getItem('isLandscape', true);
  },
  getNotificationClosed() {
    return _localstorage.getItem('isNotificationClosed', true);
  },
  setNotificationClosed(value) {
    return _localstorage.setItem('isNotificationClosed', value, true);
  },
  get(key, fromSessionStorage = false) {
    return _localstorage.getItem(key, fromSessionStorage);
  },
  set(key, value, saveToSessionStorage = false) {
    return _localstorage.setItem(key, value, saveToSessionStorage);
  },
};

const _localstorage = {
  getItem(key, fromSessionStorage = false) {
    key = generateKey(key);
    const storage = selectStorage(fromSessionStorage);

    const val = storage?.getItem(key);

    if (val === null) return undefined;

    try {
      return JSON.parse(val);
    } catch (error) {
      return val; // if not, simply return the value.
    }
  },

  setItem(key, value, saveToSessionStorage = false) {
    key = generateKey(key);
    const storage = selectStorage(saveToSessionStorage);

    if (value === undefined || value === null) {
      return storage?.removeItem(key);
    }

    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    storage.setItem(key, value);
  },
};

function selectStorage(useSessionStorage) {
  if (typeof window === 'undefined') return null;
  return !useSessionStorage ? localStorage : sessionStorage;
}

function generateKey(key) {
  return '__bharathcash__.' + key;
}
